$a: 90px;
$b: 90px;
$s: 40;
$pi: 3.14159265359;
$_precision: 10;

@function pow($base, $exp) {
  $value: $base;
  @if $exp > 1 {
    @for $i from 2 through $exp {
      $value: $value * $base;
    }
  }
  @if $exp < 1 {
    @for $i from 0 through -$exp {
      $value: $value / $base;
    }
  }
  @return $value;
}
@function fact($num) {
  $fact: 1;
  @if $num > 0 {
    @for $i from 1 through $num {
      $fact: $fact * $i;
    }
  }
  @return $fact;
}
@function _to_unitless_rad($angle) {
  @if unit($angle) == "deg" {
    $angle: $angle / 180deg * $pi;
  }
  @if unit($angle) == "rad" {
    $angle: $angle / 1rad;
  }
  @return $angle;
}
@function sin($angle) {
  $a: _to_unitless_rad($angle);
  $sin: $a;
  @for $n from 1 through $_precision {
    $sin: $sin + (pow(-1, $n) / fact(2 * $n + 1)) * pow($a,  (2 * $n + 1));
  }
  @return $sin;
}

@function cos($angle) {
  $a: _to_unitless_rad($angle);
  $cos: 1;
  @for $n from 1 through $_precision {
    $cos: $cos + (pow(-1, $n) / fact(2*$n)) * pow($a, 2*$n);
  }
  @return $cos;
}

@function tan($angle) {
  @return sin($angle) / cos($angle);
}
.start-container {
  position: absolute;
  width: $a*2;
  height: $b*2;
  border-radius: 100%;
}
.star {
  left: $a - 10px;
  top: $b - 10px;
  max-width: 35px;
  max-height: 35px;
  svg{
    width: 100%;
    height: 100%;
    g {
      @include prefixes('animation',starMedium 40s linear infinite);
      //animation: ;
      transform-origin: center center;
      transform-box: fill-box;
      stroke: black;
      stroke-width: 10;
    }
  }
  position: absolute;
  @include prefixes('animation',move 12s linear infinite);

}

@for $i from 1 through 12 {
  .star:nth-child(#{$i}) {
    $delay:-$i+s;
    animation-delay:$delay;
  }
}
@mixin loop($index) {
  $keyframeSel: $index * 100%/$s;
  $sd: cos(360deg/$s*$index);
  $x: $a * $sd;
  $y: $b * sin(360deg/$s*$index);
  #{$keyframeSel} {
    transform: translate($x, $y);
  }
}
@include key_frames('move')
{
  $i: 0;
  @while $i < $s+1 {
    $keyframeSel: $i * 100%/$s;
    $sd: cos(360deg/$s*$i);
    $x: $a * $sd;
    $y: $b * sin(360deg/$s*$i);
    #{$keyframeSel} {
      transform: translate($x, $y);
    }
    $i: $i + 1;
  }
}

.star-medium {

}
@include key_frames('starMedium'){
  0% {
    fill: #fff31e;
    opacity: .3;
    transform: rotate(0deg) scale(1);
  }
  50% {
    fill: #fff311;
    opacity: 1;
    transform: rotate(-180deg) scale(0.8);
  }
  100% {
    fill: #fff31e;
    opacity: .3;
    transform: rotate(-360deg) scale(1);
  }

}
