// Variables
$logo-size: 350px;
$base-font: 16px;

// Mixins
@mixin center($offset) {
  position: relative;
}

// Functions
@function rem-calc($target, $context: $base-font, $unit: 'em'){
  @return ($target/$context)#{$unit};
}

// Styles
[src='img/google-authenticator.svg']{
  display: block;
  width: rem-calc($logo-size);
  height: rem-calc($logo-size);
  @include center(rem-calc($logo-size / 2));
  border-radius: rem-calc($logo-size);
  transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;

  &::before,
  &::after {
    content:'';
    display: block;
  }

  &::before{
    width: rem-calc(28px);
    height: rem-calc(38px);
    position: absolute;
    top: rem-calc(423px);
    left: rem-calc(215px);
    border-bottom-right-radius: rem-calc($logo-size / 2);
    background-color: #FFCA38;
    transform: rotate(30deg);
  }

  &::after{
    width: rem-calc(170px);
    height: rem-calc(170px);
    @include center(rem-calc(85px));
    border-radius: rem-calc($logo-size / 2);
    background-color: #228EF4;
    box-shadow: 0 0 0 rem-calc(15px) rgba(255, 255, 255, 1);
  }

  &:hover {
    transform: rotate(-720deg) scale(0.9);
  }
}